.gcp-logo {
    width: 200px;
    height: auto;
    margin-bottom: 16px; /* Adjust according to your theme.spacing(2) */
}

.disclaimer-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Add more styling as needed, for example, setting a height or padding */
  }