.profile-bg-image {
    background-image: url('~/public/profile.webp');
    background-size: cover;
    background-position: center;
    height: calc(100vh - 64px); /* Make sure it covers the full viewport height */
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.contact-button {
    width: 8rem;
}