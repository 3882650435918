.mobile-header {
    display: flex;
    /* justify-content: space-between; */
}

.desktop-header {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

}

.drawer-link {
    color: white;
}

.header-links {
    display: flex;
  }

.language-switcher {
    margin-left: auto;
} 