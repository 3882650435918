.project-bg-image {
    background-image: url('~/public/playground.png');
    background-size: cover;
    background-position: center;
    height: calc(100vh - 64px); /* Make sure it covers the full viewport height */
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.project-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the space between cards */
    align-items: flex-start;
    justify-content: center;
  }
  
  .project-card {
    flex: 1;
    height: 100%; /* Make card take full height of the flex container */
    min-height: 200px; /* Adjust the min-height as needed */
    max-width: 600px; /* Keep your max-width if necessary */
    align-items: flex-start;
    justify-content: flex-start;
    /* Your other styles */
  }
  