.chatbot-bg-image {
  background-image: url('~/public/chatbot.webp');
  background-size: cover;
  background-position: center;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  width: 100vw;
}

.chat-frame {
    width: 100%;
    max-width: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    padding-bottom: 100px; /* Make space for the input-container */
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }


.chatbot-header {
    display: flex;
    justify-content:space-between;
}

/* Media query for screens smaller than 600px */

.message-list {
    overflow-y: auto;
    height: calc(100% - 48px); /* Subtract the input-container height */
    flex-grow: 1;
}

.message-item {
    display: flex;
    width: 100%;
    margin-bottom: 8px; /* Spacing between messages */
}

.message-item.user-message {
    justify-content: flex-end;
}

.message-bubble {
    max-width: 60%;
    padding: 12px;
    border-radius: 20px;
    color: white; /* Ensuring text is visible on colored backgrounds */
    word-wrap: break-word; /* Wrap text within the bubble */
    white-space: pre-wrap; /* Respect newlines and spaces in the message text */
}

.user-message .message-bubble {
    background-color: #0D1B2A; /* User message color */
}

.nudge-message .message-bubble {
    background-color: #F95738; /* User message color */
}

.bot-message .message-bubble {
    background-color: #F95738; /* Bot message color */
}

.input-container {
    display: flex;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.textarea-autosize {
    flex-grow: 1;
    border: none;
    resize: none;
    padding: 8px;
    margin-right: 8px;
    border-radius: 4px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; /* Match your theme's font */
    font-size: 1rem; /* Match your theme's font size */
}

@media (max-width: 600px) {
    .chat-frame {
      margin: 0.5rem; /* Reduced margin for smaller screens */
      max-width: 100%; /* Use the full width of the screen */
      padding: 0.5rem; /* Reduced padding for smaller screens */
      height: calc(100vh - 64px); /* Adjust height to take into account smaller screen size */
      padding-bottom: 80px; /* Adjust padding-bottom for the input-container on smaller screens */
    }
  
    .chatbot-bg-image {
      padding: 0.5rem; /* Reduced padding for smaller screens */
    }
  
    .message-bubble {
      max-width: 85%; /* Messages take more space on smaller screens */
    }
  }

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.loading-dots span {
    animation: blink 1.4s infinite both;
}

.loading-dots span:nth-child(1) {
    animation-delay: 0s;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}